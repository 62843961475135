<template>
  <div class="login-page ">

    <div class="flex-center-between top-main">
      <div class="title">{{i18n.t('login.title')}}</div>
      <div class="lang" @click="toLang">
        <img :src="langIcon" alt="" v-show="langIcon">

        <div class="lang-list" v-show="showLang">
          <div class="list" v-for="(item, i) in langList" :key="i" @click.stop="changeLang(item)">
            <img :src="item.icon" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="login-form">
      <div class="form-item">
        <div class="label">{{i18n.t('login.label1')}}</div>
        <div class="input ">
          <input type="text" v-model="form.username"  @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder1')"/>
        </div>
      </div>

      <div class="form-item ">
        <div class="label">{{i18n.t('login.label2')}}</div>
        <div class="input " >
          <input type="password" v-model="form.password"   @focus="onInputFocus" @blur="onInputBlur" :placeholder="i18n.t('login.labelPlaceholder2')"/>
        </div>
      </div>

      <div class="form-item flex-center-between desc">
        <div class="info" @click="toKefu">{{i18n.t('login.forget')}}</div>
        <div class="info green" @click="toRegister">{{i18n.t('login.register')}}</div>
          <Checkbox icon-size="14px" v-model="checked" shape="square">{{i18n.t('login.savePassword')}}</Checkbox>
        <!-- <div>
        </div> -->
      </div>

      <div class="btn-box mt-20">
        <div class="btn-line flex-center">
          <Button type="info" class="flex-1 blue-btn" @click="submit">{{i18n.t('login.btn')}}</Button>
        </div>
      </div>
    </div>
    <div class="bottom-desc" v-show="showBottomText">
      <span>{{i18n.t('login.account')}}</span>
      <span class="green" @click="toRegister">{{i18n.t('login.register')}}</span>&nbsp; <br /><br />
      <span class="green" @click="toKefu" v-if="visitor_service == 1">{{i18n.t('login.ContactCustomerService')}}</span>
    </div>
  </div>
</template>
<script>
import {NavBar, Button, Icon, Checkbox, Dialog} from 'vant';
import { login } from '@/api/login'
import { setToken, setUser, setUserId } from '@/utils/auth'
import headerBar from '@/components/header'
const FingerprintJS = require('@fingerprintjs/fingerprintjs')

// Initialize the agent at application startup.
const fpPromise = FingerprintJS.load()
export default {
  components: {
    NavBar,
    headerBar,
    Button,
    Checkbox,
    Icon
  },
  data() {
    return {
      langList: [],
      langListBak: [
        {
          icon: require('../../assets/imgs/lang/uk.webp'),
          key: 'uk'
        },
        {
          icon: require('../../assets/imgs/lang/zh.png'),
          key: 'zh-CN'
        },
        {
          icon: require('../../assets/imgs/lang/en.png'),
          key: 'en'
        },

        {
          icon: require('../../assets/imgs/lang/ar.png'),
          key: 'ar'
        },
        {
          icon: require('../../assets/imgs/lang/es.png'),
          key: 'es'
        },
        {
          icon: require('../../assets/imgs/lang/fr.png'),
          key: 'fr'
        },
        {
          icon: require('../../assets/imgs/lang/id.png'),
          key: 'id'
        },
        {
          icon: require('../../assets/imgs/lang/ja.png'),
          key: 'ja'
        },
        {
          icon: require('../../assets/imgs/lang/ko.png'),
          key: 'ko'
        },
        {
          icon: require('../../assets/imgs/lang/pt.png'),
          key: 'pt'
        },
        {
          icon: require('../../assets/imgs/lang/ru.png'),
          key: 'ru'
        },
        {
          icon: require('../../assets/imgs/lang/th.png'),
          key: 'th'
        },
        {
          icon: require('../../assets/imgs/lang/he.png'),
          key: 'he'
        },
        {
          icon: require('../../assets/imgs/lang/tr.png'),
          key: 'tr'
        },
        {
          icon: require('../../assets/imgs/lang/hi.png'),
          key: 'hi'
        },
        {
          icon: require('../../assets/imgs/lang/vi.png'),
          key: 'vi'
        },
        {
          icon: require('../../assets/imgs/lang/de.png'),
          key: 'de'
        },
        {
          icon: require('../../assets/imgs/lang/it.png'),
          key: 'it'
        },
        {
          icon: require('../../assets/imgs/lang/kk.png'),
          key: 'kk'
        },
        {
          icon: require('../../assets/imgs/lang/hk.svg'),
          key: 'zh-TW'
        },
        {
          icon: require('../../assets/imgs/lang/ka.svg'),
          key: 'ka'
        },
        {
          icon: require('../../assets/imgs/lang/ro.svg'),
          key: 'ro'
        },
          // 保加利亚语
        {
          icon: require('../../assets/imgs/lang/bg.svg'),
          key: 'bg'
        },
        // 亚美尼亚语
        {
          icon: require('../../assets/imgs/lang/hy.png'),
          key: 'hy'
        },
      //     波兰语
        {
          icon: require('../../assets/imgs/lang/pl.png'),
          key: 'pl'
        },
        // 立陶宛语
        {
          icon: require('../../assets/imgs/lang/lt.png'),
          key: 'lt'
        },
        {
          icon: require('../../assets/imgs/lang/sk.svg'),
          key: 'sk'
        },
        {
          icon: require('../../assets/imgs/lang/fa.png'),
          key: 'fa'
        },
        // 阿塞拜疆语
        {
          icon: require('../../assets/imgs/lang/az.png'),
          key: 'az'
        },
      ],
      lang: '',
      langIcon: '',
      showBottomText: true,
      form: {
        username: '',
        password: '',
        Fingerprint: '',
        code: ''
      },
      checked: false,
      initialHeight: window.innerHeight,
      showLang: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    language() {
      return this.$store.state.language
    },
    visitor_service(){
      return this.$store.state.visitor_service
    }
  },
  watch: {
    language() {
      this.initLang()
    }
  },
  created() {
    if(this.$route.query.token !== undefined) {
      setToken(this.$route.query.token)
      setUser(this.$route.query.username)
      setUserId(this.$route.query.id)
      localStorage.setItem('username', this.$route.query.username)
      setTimeout(() => {
        this.$router.push({
          name: 'index'
        })
      }, 200)
    }
  },
  mounted() {
    if (this.language) {
      this.initLang()
    }
    let text = this.i18n.t("index.desc1")
    let ensure = this.i18n.t("common.ensure")
    // Inshallah|Shop
    // 使用 replace() 方法来替换文本


    let username = localStorage.getItem('username')
    if (username) {
      this.form.username = username
      this.form.password = localStorage.getItem('password')
      this.checked = true
    }


  },
  methods: {
    onInputFocus() {
      // 输入框获得焦点时，监听软键盘弹出事件
      window.addEventListener('resize', this.adjustPageHeight);
      // 恢复底部文字显示
      this.showBottomText = false;
    },
    onInputBlur() {
      // 输入框失去焦点时，移除事件监听
      window.removeEventListener('resize', this.adjustPageHeight);
      // 恢复底部文字显示
      this.showBottomText = true;
    },
    adjustPageHeight() {
      // 获取当前页面高度
      const newHeight = window.innerHeight;

      // 如果当前页面高度小于初始高度，表示软键盘弹出
      if (newHeight < this.initialHeight) {
        // 调整页面高度，可以使用你喜欢的动画效果
        document.body.style.height = this.initialHeight + 'px';
        // 隐藏底部文字
        this.showBottomText = false;
      } else {
        // 恢复默认高度
        document.body.style.height = 'auto';
        // 恢复底部文字显示
        this.showBottomText = true;
      }
    },
    initLang() {
      this.langList = this.langListBak.filter(v => {
        return this.language.indexOf(v.key) > -1
      })
      this.lang = localStorage.getItem('locale') || 'en'
      let index = this.langList.findIndex(v => {
        return this.lang == v.key
      })
      if (index > -1) {
        this.langIcon = this.langList[index].icon
      }
    },
    toKefu(){
        this.$router.push({
            name: 'kefu'
        })
    },
    toRegister() {
      this.$router.push({
        name: 'register'
      })
    },
    submit() {
      if (!this.form.username) {
        this.$toast({
          message: this.i18n.t('login.labelPlaceholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('login.labelPlaceholder2'),
          icon: 'warning-o'
        })
        return
      }
      let form = new FormData()
      form.append('user_name', this.form.username)
      form.append('password', this.form.password)
      form.append('Fingerprint', this.form.Fingerprint)
      form.append('token', true)
      login(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.i18n.t('login.success'))
          setToken(res.data.token)
          setUser(this.form.username)
          setUserId(res.data.id)
          localStorage.setItem('username', this.form.username)
          if (this.checked) {
            localStorage.setItem('password', this.form.password)
          }
          this.$router.push({
            name: 'index'
          })
        } else {
          this.$toast({
            message: res.msg,
            icon: 'warning-o'
          })
        }
      })
    },
    toLang() {
      this.showLang = !this.showLang
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.langIcon = data.icon
    }
  }
}
</script>